import { Profile, ProfileConfig } from 'lib/types/profileType';
import { logError } from 'lib/utils/logReportHelper';

const getProfileSdkResponse = async (configs: ProfileConfig): Promise<Profile> => {
  try {
    const { ProfileConfig, Profile } = await import('@foxcorp/foxkit-profile-js');
    const config = new ProfileConfig(configs);
    return new Profile(config);
  } catch (error) {
    const responseError = 'Profile Mounting Error';
    logError(error, { name: responseError });
    return Promise.reject(error);
  }
};

export default getProfileSdkResponse;
