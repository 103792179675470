import React, { FC } from 'react';

const Apple: FC = () => {
  return (
    <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path d="M18.685,12.678c-0.027,-2.949 2.408,-4.364 2.519,-4.433c-1.371,-2.005 -3.503,-2.28 -4.264,-2.312c-1.814,-0.183 -3.541,1.068 -4.46,1.068c-0.919,0 -2.341,-1.041 -3.845,-1.012c-1.979,0.029 -3.802,1.15 -4.821,2.921c-2.055,3.564 -0.524,8.847 1.476,11.74c0.979,1.414 2.145,3.008 3.679,2.949c1.477,-0.059 2.035,-0.955 3.818,-0.955c1.783,0 2.285,0.955 3.846,0.927c1.588,-0.031 2.595,-1.445 3.567,-2.864c1.122,-1.642 1.587,-3.231 1.613,-3.314c-0.035,-0.015 -3.096,-1.188 -3.128,-4.715" />
        <path
          id="path190"
          d="M15.752,4.02c0.813,-0.985 1.362,-2.356 1.211,-3.721c-1.172,0.047 -2.59,0.781 -3.432,1.766c-0.753,0.871 -1.414,2.266 -1.235,3.604c1.307,0.102 2.642,-0.665 3.456,-1.649"
        />
      </g>
    </svg>
  );
};

export default Apple;
