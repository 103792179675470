import React, { memo } from 'react';
import NextLink from 'next/link';

export type Props = {
  href: string;
  target?: string;
  external?: boolean;
  passHref?: boolean;
  children: React.ReactNode;
  className?: string;
};

const Link = ({
  href = '',
  target = '',
  external,
  passHref,
  children,
  className,
}: Props): JSX.Element => {
  if (external) {
    return (
      <a className={className} href={href} target={target}>
        <>{children}</>
      </a>
    );
  } else
    return (
      <NextLink href={href} passHref={passHref}>
        <a className={className}>{children}</a>
      </NextLink>
    );
};

export default memo(Link);
