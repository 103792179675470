import { useContext, useEffect, useState } from 'react';
import { setError } from 'context/state/reducer';
import { AppContext } from 'context/GlobalContextProvider';
import { Profile, ProfileConfig } from 'lib/types/profileType';

const useGetProfileSdk = (configs: ProfileConfig): Profile => {
  const [profile, setProfile] = useState<Profile>({} as Profile);
  const { dispatch } = useContext(AppContext);
  useEffect(() => {
    let didCancel = false;
    const fetchProfile = async () => {
      try {
        const { ProfileConfig, Profile } = await import('@foxcorp/foxkit-profile-js');
        if (!didCancel) {
          const config = await new ProfileConfig(configs);
          const profile = await new Profile(config);
          setProfile(profile);
        }
      } catch (e) {
        dispatch(setError(e));
      }
    };
    fetchProfile()
      .then()
      .catch((e) => {
        dispatch(setError(e));
      });
    return () => {
      didCancel = true;
    };
  }, [configs, dispatch]);
  return profile;
};

export default useGetProfileSdk;
