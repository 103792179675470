export const ACCOUNT_COOKIE_NAME_FOX = 'ACTIVATE_ACCOUNT';
export const COOKIE_CODE_INFO = 'code-info';
export const FLOW_STAGE_ENTER_REG_CODE = 'ENTER_REG_CODE';
export const FLOW_STAGE_GEN_REG_CODE = 'GEN_REG_CODE';
export const FLOW_STAGE_MVPD_SELECT = 'MVPD_SELECT';
export const FLOW_STAGE_PROFILE_AUTH = 'PROFILE_AUTH';
export const FLOW_STAGE_MVPD_AUTH = 'MVPD_AUTH';
export const FLOW_STAGE_MVPD_LINK = 'MVPD_LINK';
export const ANONYMOUS = 'anonymous';
export const COOKIE_CAMPAIGN_CODES = 'c_m';
export const INT_CMP = 'int_cmp';
export const CODE_TYPE_API = 'api';
export const ROUTE_HOME = '/';
export const ROUTE_MVPD = '/mvpd/';
export const ROUTE_SUCCESS = '/success/';
export const ROUTE_ACCOUNT = '/account/';
export const ROUTE_ACCOUNT_NATION = '/account/signin/tv/';
export const PATH_ACTIVATE = '/activate/';
export const REDIRECT_SECONDS = 5;
export const ACTIVATE_CODE_EXPIRES_MINUTES = 30;
export const GOOGLE = 'google';
export const FACEBOOK = 'facebook';
export const DEBUG_PANEL_API_INTEGRATION = 'Integration';
export const DEBUG_PANEL_API_BASEURL_INT = 'https://int1.api3.fox.com/v2.0';
export const PREVIEW_PASS_5_MVPD = 'TempPass_fbcfox_5min';
export const PREVIEW_PASS_60_MVPD = 'TempPass_fbcfox_60min';

export const SECOND_SCREEN_VERSION = 'v2';

export enum Brand {
  SPORTS = 'sports',
}

export enum ENVS {
  PRODUCTION = 'production',
  STAGE = 'stage',
  DEV = 'dev',
}

export enum DataDogService {
  SPORTS = 'fox-sports-ssa',
}

export enum ExternalBrand {
  SPORTS = 'foxsports',
}

export enum ExternalENVFile {
  PRODUCTION = 'info.json',
  STAGE = 'info-qa.json',
  DEV = 'info-qa.json',
}

export const LINKS = {
  HELP_LINK: 'https://help.fox.com/s/topic/0TO1H000000HHQVWA4/fox-sports',
  TERMS_LINK: 'https://www.foxsports.com/terms-of-use',
  DO_NOT_SELL_LINK: 'https://www.foxsports.com/do-not-sell',
  PRIVACY_POLICY: 'https://www.foxsports.com/privacy-policy',
  target: '_blank',
};
