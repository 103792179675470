import React, { FC } from 'react';

const CheckMark: FC = () => {
  return (
    <div>
      <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M12 0c6.627 0 12 5.373 12 12s-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0Zm5.336 6.99L10.39 14.18l-3.002-2.898L6 12.72l3.001 2.899-.001.001 1.439 1.39 8.336-8.632-1.44-1.39Z"
          fill="#FFF"
          fillRule="evenodd"
        />
      </svg>
    </div>
  );
};

export default CheckMark;
