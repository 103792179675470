import { Config } from 'lib/types/configType';
import { getConfig } from 'lib/utils/getConfig';
import { Brand, SECOND_SCREEN_VERSION } from 'constants/index';

export enum FlowStage {
  LANDING_PAGE = 'landingPage',
  EXISTING_USER = 'existingUser',
  NEW_USER = 'newUser',
  FORGOT_PASSWORD = 'forgotPassword',
  CREATE_PASSWORD = 'createPassword',
  SUCCESS = 'success',
  LINK = 'link',
}

export type AppState = {
  isLoading: boolean;
  isSocialAuth: {
    data: AnyObject;
    value: boolean;
    kind: string;
  };
  brand: string;
  account: Account | undefined;
  config: Config;
  codeInfo: {
    code?: string | undefined;
  };
  qrCode: string;
  error: unknown;
  flowStage: string;
  languageStrings: AnyObject;
  isRegister: {
    value: boolean | undefined;
    account: AnyObject;
  };
  firstScreenVersion: string;
  secondScreenVersion: string;
};

export type Account = {
  accessToken?: string;
  isNew?: boolean;
  brand?: string;
  device?: string;
  email?: string;
};

export const initialState: AppState = {
  isLoading: false,
  isSocialAuth: {
    data: {},
    value: false,
    kind: '',
  },
  brand: Brand.SPORTS,
  account: {
    accessToken: '',
    isNew: undefined,
  },
  config: getConfig(),
  codeInfo: {},
  qrCode: '',
  error: '',
  flowStage: '',
  languageStrings: {},
  isRegister: {
    value: undefined,
    account: {},
  },
  firstScreenVersion: '',
  secondScreenVersion: SECOND_SCREEN_VERSION,
};
