import React, { FC } from 'react';

const FoxSportsLogo: FC = () => {
  return (
    <svg width="80" height="49" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.494 36.078v1.894l-.7.718H3.701l-.272.274v1.818l.196.196h6.726l1.495 1.499v4.336l-1.723 1.723H0v-1.757l.899-.87h7.258l.274-.272v-1.863l-.198-.2H1.495L0 42.079V37.8l1.724-1.721h9.77Zm12.63 0 1.922 1.919v4.617l-1.921 1.923h-6.707v3.998h-3.433V36.078h10.14Zm14.041 0 1.922 1.92v8.622l-1.922 1.915h-8.417l-1.904-1.915v-8.623l1.904-1.92h8.417Zm14.406 0 1.724 1.721v3.978l-1.646 1.646 2.167 5.112h-3.817l-1.612-4.518h-3.632v4.518h-3.43V36.078h10.246Zm14.243 0v2.886H62.85v9.572h-3.431v-9.572h-3.966v-2.886h11.36Zm12.587 0v1.894l-.702.718h-7.086l-.274.274v1.818l.196.196h6.727l1.494 1.499v4.336l-1.726 1.723H67.904v-1.757l.9-.87h7.26l.274-.272v-1.863l-.198-.2h-6.738l-1.498-1.495V37.8l1.726-1.721H79.4ZM35.95 38.78h-3.99l-.701.703v5.438l.7.697h3.99l.71-.697v-5.438l-.71-.703Zm-13.855-.061h-4.677v3.39h4.677l.504-.501v-2.386l-.504-.503Zm28.28-.09h-4.62v2.792h4.62l.489-.489v-1.815l-.488-.488ZM21.132 0l.625 9.242H10.362v4.407h9.286v9.224h-9.352v10.924H.78V0h20.35Zm38.842.05 4.155 7.276L68.355.05h10.19l-9.253 16.162 10.184 17.534H68.941l-4.94-8.525-4.89 8.54-10.24.006 9.984-17.442L49.43.05h10.543ZM37.598.51c8.898 0 16.109 7.36 16.109 16.43 0 9.08-7.21 16.436-16.11 16.436-8.891 0-16.098-7.356-16.098-16.436 0-9.07 7.207-16.43 16.099-16.43Zm.047 6.758c-1.506 0-2.726 1.383-2.726 2.92l-.005 13.46c0 1.54 1.225 2.789 2.731 2.789 1.51 0 2.85-1.204 2.85-2.748V10.188c0-1.537-1.34-2.92-2.85-2.92Z"
        fill="#FFF"
        fillRule="evenodd"
      />
    </svg>
  );
};

export default FoxSportsLogo;
