import React, { createContext, useReducer } from 'react';
import { reducer } from './state/reducer';
import { AppActions } from './state/actions';
import { AppState, initialState } from './state/state';

type Dispatch = (action: AppActions) => void;

const AppContext = createContext<{
  state: AppState;
  dispatch: Dispatch;
}>({
  state: initialState,
  dispatch: () => null,
});

type Props = {
  children: React.ReactNode;
};

const GlobalContextProvider = ({ children }: Props): JSX.Element => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return <AppContext.Provider value={{ state, dispatch }}>{children}</AppContext.Provider>;
};

export { GlobalContextProvider, AppContext };
