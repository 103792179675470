//TODO This should only be used on non-sesitive information

import useSWR from 'swr';
import { ENVS, ExternalBrand, Brand, ExternalENVFile } from 'constants/index';

const fetcher = (url: string) => fetch(url).then((res) => res.json());

export type Config = {
  externalConfig: Record<string, string>;
  isLoading: boolean;
  isError: boolean;
};

const getBrandConst: AnyObject = {
  [Brand.SPORTS]: ExternalBrand.SPORTS,
};

const getENVConstFile: AnyObject = {
  [ENVS.PRODUCTION]: ExternalENVFile.PRODUCTION,
  [ENVS.STAGE]: ExternalENVFile.STAGE,
  [ENVS.DEV]: ExternalENVFile.DEV,
};

const useExternalStrings = (): Config => {
  const brandConst = getBrandConst[Brand.SPORTS];
  const envConstFile = getENVConstFile[process.env.NEXT_PUBLIC_ENVIRONMENT || (ENVS.DEV as string)];
  // This URL should be changed to another nondetermental URL
  const url = `https://config.foxdcg.com/${brandConst}/ssa/${envConstFile}`;
  const { data, error } = useSWR(url, fetcher, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  return {
    externalConfig: data,
    isLoading: !error && !data,
    isError: error,
  };
};

export default useExternalStrings;
