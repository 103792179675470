import useSWR from 'swr';

const fetcher = (url: string) => fetch(url).then((res) => res.json());

export type Language = {
  language_strings_en_US: Record<string, string>;
  isLoading: boolean;
  isError: boolean;
};

const useLanguageStrings = (): Language => {
  const url = 'https://config.foxdcg.com/foxnow/web/lang/strings-en_US.json';
  const { data, error } = useSWR(url, fetcher, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  return {
    language_strings_en_US: data,
    isLoading: !error && !data,
    isError: error,
  };
};

export default useLanguageStrings;
