import React, { useContext } from 'react';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';
import { AppContext } from 'context/GlobalContextProvider';
import { setError } from 'context/state/reducer';

const ErrorFallback = ({ error, resetErrorBoundary }: FallbackProps): JSX.Element | null => (
  <div role="alert">
    <p>Something went wrong:</p>
    <pre>{error.message}</pre>
    <button onClick={resetErrorBoundary}>Try again</button>
  </div>
);

type Props = {
  children: React.ReactNode;
};

const ErrorBoundaryProvider = ({ children }: Props): JSX.Element => {
  const { dispatch } = useContext(AppContext);
  const handleBoundaryError = (error: Error, info: { componentStack: string }) => {
    dispatch(setError({ error, info }));
  };
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback} onError={handleBoundaryError}>
      {children}
    </ErrorBoundary>
  );
};

export default ErrorBoundaryProvider;
