import React, { ReactNode } from 'react';
import Head from 'next/head';
import Header from '../Header';

interface Props {
  children?: ReactNode;
}

const Layout = ({ children }: Props): JSX.Element => {
  return (
    <>
      <Head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>Second Screen Activation | FOX Broadcasting Company</title>
        <link rel="apple-touch-icon" sizes="180x180" href={`/favicons/apple-icon-180x180.png`} />
        <link rel="icon" type="image/png" sizes="32x32" href={`/favicons/favicon-32x32.png`} />
        <link rel="icon" type="image/png" sizes="16x16" href={`/favicons/favicon-16x16.png`} />
      </Head>
      <Header />
      <main>{children}</main>
    </>
  );
};

export default Layout;
