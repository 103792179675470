const color = {
  altoLight: '#d8d8d8',
  azureRadiance: '#007ee4',
  black: '#000000',
  bluePrimary: '#0086bd',
  border: '1px solid #0059B5',
  endeavour: '#0059B5',
  error: '#d0021b',
  errorRed: '#d0021b',
  header: 'linear-gradient(to right top, #0b1219 0%, #1b222a) fixed',
  Monza: '#d0021b',
  sanMarino: '#4267b2',
  sportsGradientBodyBackground: 'linear-gradient(to right top, #000409,rgb(29 34 39 / 98%)) fixed',
  transparent: 'transparent',
  white: '#ffffff',
  white05: 'rgba(255, 255, 255, 0.05)',
  white10: 'rgba(255, 255, 255, 0.1)',
  white25: 'rgba(255, 255, 255, 0.25)',
  white50: 'rgba(255, 255, 255, 0.5)',
  body: 'linear-gradient(to right top, #000409,rgb(29 34 39 / 98%)) fixed',
  button: {
    background: '#0059B5',
    backgroundHover: '#FFFFFF',
    disabled: '#FFFFFF',
    hollowBg: 'transparent',
    hollowBorder: '1px solid white',
    text: '#FFFFFF',
    textHover: '#000000',
  },
};

export default color;
