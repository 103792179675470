import React, { useEffect } from 'react';
import styled from 'styled-components';
import { CookiesProvider } from 'react-cookie';
import type { AppProps } from 'next/app';
import 'regenerator-runtime/runtime.js';
import { GlobalContextProvider } from 'context/GlobalContextProvider';
import { isIntegrationApi, profileConfig } from 'lib/utils/configs';
import { getConfig } from 'lib/utils/getConfig';
import { useGetProfileSdk } from 'lib/hooks';
import initializeDataDog from 'lib/utils/initializeDataDog';
import ErrorBoundaryProvider from 'components/common/ErrorBoundaryProvider';
import Layout from 'components/common/Layout';
import GlobalStyle from 'styles/GlobalStyles';
import FontStyles from 'styles/fonts';

const App = ({ Component, pageProps }: AppProps): JSX.Element => {
  const profile = useGetProfileSdk(profileConfig);
  const config = getConfig();

  useEffect(() => {
    config && initializeDataDog(config.dataDog);
  }, [config]);

  return (
    <>
      <CookiesProvider>
        <GlobalContextProvider>
          <ErrorBoundaryProvider>
            <Layout>
              <Component config={config} profile={profile} {...pageProps} />
              {isIntegrationApi() && (
                <Integration>Integration API: https://int1.api3.fox.com/v2.0</Integration>
              )}
            </Layout>
            <GlobalStyle />
            <FontStyles />
          </ErrorBoundaryProvider>
        </GlobalContextProvider>
      </CookiesProvider>
    </>
  );
};

export default App;

const Integration = styled.p`
  position: fixed;
  bottom: 20px;
  right: 40px;
  opacity: 0.4;
`;
