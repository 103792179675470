import React, { FC } from 'react';

const Edit: FC = () => {
  return (
    <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path
          d="M17.456058,5.91891193 L19.267767,4.10710678 C20.2104357,3.16061199 20.2409263,2.17220389 19.3855417,1.29581982 L18.7047254,0.594712568 C17.8493408,-0.264143816 16.8118716,-0.15537726 15.8692029,0.773589849 L14.0951402,2.54613078 L17.456058,5.91891193 Z M5.44370412,17.9355005 L16.7490401,6.62724495 L16.7490401,6.62724495 L13.3743541,3.25561154 L2.06525646,14.5668948 C1.85756188,14.7746295 1.69817751,15.0255655 1.59843283,15.3018656 L0.0278603893,19.6524669 C-0.0190221021,19.7823348 0.0482509052,19.9256194 0.178118857,19.9725018 C0.23290799,19.9922808 0.292884023,19.9923069 0.347690376,19.9725757 L4.70677405,18.4032346 C4.98385771,18.30348 5.23549275,18.1437657 5.44370412,17.9355005 Z"
          id="Edit"
        />
      </g>
    </svg>
  );
};

export default Edit;
