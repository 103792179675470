import { useContext, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { CookieSetOptions } from 'universal-cookie';
import { AppContext } from 'context/GlobalContextProvider';
import { ACCOUNT_COOKIE_NAME_FOX, COOKIE_CODE_INFO } from 'constants/index';

type FlowManager = {
  isMVPD: boolean;
  code: string;
  setIsNoCodeOrAccount: (value: ((prevState: boolean) => boolean) | boolean) => void;
  removeCookie: (
    name: typeof ACCOUNT_COOKIE_NAME_FOX | typeof COOKIE_CODE_INFO,
    value?: never,
    options?: CookieSetOptions | undefined,
  ) => void;
  accountType: string;
  setCookie: (
    name: typeof ACCOUNT_COOKIE_NAME_FOX | typeof COOKIE_CODE_INFO,
    value?: unknown | AnyObject,
    options?: CookieSetOptions | undefined,
  ) => void;
  isNew: boolean;
  accessToken: string;
  cookies: {
    ACTIVATE_ACCOUNT?: AnyObject;
    'code-info'?: AnyObject;
    FOXSSOACCT?: AnyObject;
  };
  isAnonymous: boolean;
  isNoCodeOrAccount: boolean;
  flowStage: string;
};

function useFlowManager(): FlowManager {
  const [code, setCode] = useState('');
  const [isNew, setIsNew] = useState(false);
  const [isMVPD, setIsMVPD] = useState(false);
  const [isAnonymous, setIsAnonymous] = useState(false);
  const [accessToken, setAccessToken] = useState('');
  const [isNoCodeOrAccount, setIsNoCodeOrAccount] = useState(false);

  const [cookies, setCookie, removeCookie] = useCookies([
    ACCOUNT_COOKIE_NAME_FOX,
    COOKIE_CODE_INFO,
  ]);

  const {
    state: {
      isRegister: { value: isRegister },
      flowStage,
    },
  } = useContext(AppContext);

  useEffect(() => {
    setIsNoCodeOrAccount(!cookies[COOKIE_CODE_INFO] || !cookies[ACCOUNT_COOKIE_NAME_FOX]);
    setCode(cookies[COOKIE_CODE_INFO]?.code);
    setAccessToken(cookies[ACCOUNT_COOKIE_NAME_FOX]?.accessToken);
    setIsMVPD(cookies[COOKIE_CODE_INFO]?.isMvpd);
    setIsAnonymous(cookies[COOKIE_CODE_INFO]?.isAnonymous);
    setIsNew(cookies[ACCOUNT_COOKIE_NAME_FOX]?.isNew);
  }, [cookies, isRegister]);

  return {
    accountType: '',
    isAnonymous,
    accessToken,
    code,
    cookies,
    isNew,
    isMVPD,
    isNoCodeOrAccount,
    setIsNoCodeOrAccount,
    removeCookie,
    setCookie,
    flowStage,
  };
}

export default useFlowManager;
