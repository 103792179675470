import React, { FC } from 'react';

const Facebook: FC = () => {
  return (
    <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path
          id="icon-fb"
          d="M30.1834292,15.0917146 C30.1834292,6.75678464 23.4266445,0 15.0917146,0 C6.75678464,0 0,6.75678464 0,15.0917146 C0,22.6244256 5.5188219,28.8679151 12.7336342,30 L12.7336342,19.4541633 L8.90175353,19.4541633 L8.90175353,15.0917146 L12.7336342,15.0917146 L12.7336342,11.7668212 C12.7336342,7.98446025 14.9867211,5.89520101 18.4339988,5.89520101 C20.0851562,5.89520101 21.8122437,6.18996106 21.8122437,6.18996106 L21.8122437,9.9039377 L19.9092139,9.9039377 C18.0344516,9.9039377 17.449795,11.0672672 17.449795,12.2607506 L17.449795,15.0917146 L21.6353877,15.0917146 L20.9662824,19.4541633 L17.449795,19.4541633 L17.449795,30 C24.6646073,28.8679151 30.1834292,22.6244256 30.1834292,15.0917146"
        />
      </g>
    </svg>
  );
};

export default Facebook;
