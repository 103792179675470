import { datadogRum } from '@datadog/browser-rum';
import { Brand, DataDogService } from 'constants/index';
import packageJson from '../../package.json';

const getBrandService: AnyObject = {
  [Brand.SPORTS]: DataDogService.SPORTS,
};

export default function initializeDataDog(config: AnyObject = {}): void {
  if (config) {
    const { applicationId, clientToken, env, site } = config;
    datadogRum.init({
      applicationId,
      clientToken,
      site,
      service: getBrandService[Brand.SPORTS],
      env,
      version: packageJson.version,
      sampleRate: 100,
      trackInteractions: true,
      defaultPrivacyLevel: 'mask-user-input',
    });
    datadogRum.startSessionReplayRecording();
  }
}
