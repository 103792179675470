const breakpoints = {
  xs: '480px',
  sm: '768px',
  md: '992px',
  lg: '1200px',
  bp_375: '375px',
  bp_767: '767px',
  bp_768: '768px',
  bp_1024: '1024px',
  bp_1099: '1099px',
  bp_1440: '1440px',
  bp_2560: '2560px',
};

export default breakpoints;
